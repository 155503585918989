<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Departments</h1>
        </v-container>
        <v-card outlined shaped tile class="pa-1">

            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-text-field
                                    v-model="search"
                                    data-cy="search-department"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" color="success" @click.stop="showDepartmentForm('CREATED')" v-if="hasPermission('ROLE_CREATE_DEPARTMENT')">Create Department</v-btn>
                                <department-form ref="form" :selected-item="selectedDepartment" :is-show="showFormDialog" :form-type="departmentFormType" v-on:close-dialog="closeDialog" v-on:save-department="saveDepartment" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table
          :hide-default-header="true"
          :headers="headers"
          :items="displayDepartments"
          :search="search"
          :loading="loading"
          :server-items-length="totalDepartments.length">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.isEnabled }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" @click.stop="showDepartmentForm('UPDATE', item)" v-if="hasPermission('ROLE_UPDATE_DEPARTMENT')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import DepartmentForm from '@/views/components/DepartmentForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Departments',
    components: {
        Loading,
        DepartmentForm
    },
    data() {
        return {
            //filter/search variables
            search: '',
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayDepartments: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            departmentFormType: 'CREATE',
            selectedDepartment: {},
            paymentMethods: [],
            totalDepartments: 0,
            details: {
                title: '',
                item: {}
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x === authority)){
            return true
          }
          return false
        },
        async queryData() {
            this.loading = true
            try {
                const departmentListResponse = await API.getDepartments()
                console.log(departmentListResponse)
                if (!departmentListResponse || departmentListResponse.error) {
                    //error getting data
                    console.log(`${departmentListResponse.error}`)
                } else {
                    this.totalDepartments = departmentListResponse.length
                    this.displayDepartments = departmentListResponse
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Department ID',
                    value: 'id'
                },
                {
                    text: 'Department Name',
                    value: 'name'
                },
                {
                    text: 'Date Updated',
                    value: 'lastUpdated',
                    sortable: false
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Is Enabled',
                    value: 'isEnabled'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showDepartmentForm(what, item = {}) {
            this.departmentFormType = what
            this.selectedDepartment = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveDepartment(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new department <strong><i>${value.departmentName}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createDepartments(value)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error.message
                        } else {
                            this.loadingMessage = `Successfully created department <strong><i>${value.departmentName}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.form.resetForm()
                        }
                    }, 2000);
                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating department <strong><i>${value.departmentName}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateDepartments(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error.message
                        } else {
                            this.loadingMessage = `Successfully updated department <strong><i>${value.departmentName}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.form.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        // search(event, type) {
        //     this.query[type] = event
        // }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
    margin-bottom: 50px;
}
</style>
